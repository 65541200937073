<template>
  <div class="digital">
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList" class="scrollList">
        <!--banner img 图片-->
        <headSearch :search-out="headGo" />
        <div class="digital_img" @click="btnBanner(listData.banner[0])">
          <img :src="listData.banner[0].url" alt="">
        </div>
        <!--金刚位 10个 img-->
        <div class="digital_list">
          <div
            v-for="(item, index) in listData.category"
            :key="index"
            class="digital_list_son"
            @click="btnCategory(item)"
          >
            <div class="digital_list_son_img">
              <img :src="item.productImage" alt="">
            </div>
            <h4>{{ item.categoryThreeName }}</h4>
          </div>
        </div>
        <!--数码 家具 中间 4个 img 图片-->
        <div v-if="temptype == 3 || temptype == 2 || temptype == 1">
          <div
            v-if="
              temptype == 1 &&
                listData.activity &&
                listData.activity.length != 0
            "
            class="digital_mid_list"
          >
            <div
              v-for="(item, index) in listData.activity[0].goodsInfo"
              :key="index"
              class="digital_mid_list_son"
              @click="btnActivity(item)"
            >
              <img :src="item.image" alt="">
            </div>
          </div>
          <!-- 中间 1个 img 山水总相逢 -->
          <div
            v-if="
              temptype == 1 &&
                listData.activity &&
                listData.activity.length != 0
            "
            class="digital_activity_img"
            @click="btnActivityImg(listData.activity[1].goodsInfo[0])"
          >
            <img :src="listData.activity[1].goodsInfo[0].image" alt="">
          </div>
          <div
            v-if="
              temptype == 2 &&
                listData.activity &&
                listData.activity.length != 0
            "
            class="digital_activity_img"
            @click="btnActivityImg(listData.activity[0].goodsInfo[0])"
          >
            <img :src="listData.activity[0].goodsInfo[0].image" alt="">
          </div>

          <!-- 左右滑动 z == 0 ? 1 :(z ==1 ? 2 : 3) -->
          <div
            v-if="temptype == 1 && listData.activity.length > 0"
            class="digital_activity_list"
          >
            <div class="group_commend_left">
              <div class="box_all">
                <div
                  v-for="(item, index) in listData.activity[
                    temptype == 1 ? 2 : 1
                  ].goodsInfo"
                  :key="index"
                  class="box_list"
                  @click="btnList(item)"
                >
                  <div class="box_list_img">
                    <img :src="item.image" alt="">
                  </div>
                  <div class="box_list_span">{{ item.goodsName }}</div>
                  <div class="box_list_type">
                    <span class="box_list_vip_price">¥{{ item.vipPrice }}</span>
                    <span class="box_list_price">¥{{ item.goodsPrice }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--为你推荐 -->
        <div class="digital_list_all">
          <h4>· 为你推荐 ·</h4>
          <div class="jd_list_box">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="jd_list"
              @click="btnGo(item)"
            >
              <div class="jd_list_ovs">
                <imgLoading
                  class="jd_list_img"
                  :src="item.goodCover"
                />
              </div>
              <div class="jd_list_son">
                <div class="jd_name">
                  <span>{{ item.goodName }}</span>
                </div>
                <div class="jd_logo_ov">
                  <span class="jd_price">¥{{ item.goodVipPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import headSearch from '@/component/head/search'
import imgLoading from '@/component/imgLoading'
import { recommendList, newCategoryList } from '@/services/api'
import mescroll from '@/component/fresh_scroll/mescroll'

export default {
  name: 'Digital',
  components: {
    imgLoading,
    mescroll,
    headSearch
  },
  data() {
    return {
      headGo: {
        isShowSearch: false, // 输入框 title 控制
        isShowReturn: true, // 返回 上一页 显隐
        bgColor: '#fff', // 背景颜色
        titleData: '',
        callbackGo: this.callbackGo
      },
      listData: {}, // list  上边
      list: '', // list 下边

      // 家电数码 8780,8778 1
      // 母婴玩具 8791,8799 2
      // 日用家具 8783,8797 3

      categoryId: '',
      channelId: 3, // channelId  穿过来的

      temptype: Number(this.$route.query.temptype), // 页面 参数  判断显示那个页面

      mescrollDown: {
        use: true,
        // top:'50px',
        bottom: '0'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true // 是否开启懒加载,默认false
        }
      }
    }
  },
  mounted() {
  },
  created() {
    const { temptype } = this.$route.query
    this.temptype = Number(temptype)
    if (Number(temptype) === 1) {
      this.headGo.titleData = '家电数码'
      document.title = '家电数码'
    } else if (Number(temptype) === 2) {
      this.headGo.titleData = '母婴玩具'
      document.title = '母婴玩具'
    } else {
      this.headGo.titleData = '日用家具'
      document.title = '日用家具'
    }
    this.gitRecommendList()
    this.gitNewCategoryList()
  },
  methods: {
    callbackGo() {
      this.$router.go(-1)
    },
    // 中间 列表 数据
    gitNewCategoryList: function() {
      if (this.temptype === 1) {
        this.categoryId = '8780,8778'
      } else if (this.temptype === 2) {
        this.categoryId = '8791,8799'
      } else {
        this.categoryId = '8783,8797'
      }
      const data = {
        categoryId: this.categoryId, // 传过来的
        channelId: this.channelId, // 传过来的
        uid: window.localStorage.getItem('uid')
      }
      newCategoryList(data).then((res) => {
        if (Number(res.code) === 200) {
          this.listData = res.data
        }
      })
    },
    // 下边 list 数据
    gitRecommendList: function() {
      if (this.temptype === 1) {
        this.categoryId = '8780,8778'
      } else if (this.temptype === 2) {
        this.categoryId = '8791,8799'
      } else {
        this.categoryId = '8783,8797'
      }
      const data = {
        categoryId: this.categoryId,
        page: 1,
        pageSize: 10
      }
      recommendList(data).then((res) => {
        if (Number(res.code) === 200) {
          this.list = res.data
        }
      })
    },

    //  头部 Banner 跳转
    btnBanner: function(t) {
      this.$router.push({ path: '/acthds', query: { id: t.id }})
    },
    // 四个 img 跳转
    btnActivity: function(t) {
      if (t.redirectType === 3) {
        const obj = {
          product_type: 1,
          goodId: t.id,
          skuId: t.skuId
        }
        this.$store.dispatch('godetail', obj)
      } else if (t.redirectType === 29) {
        window.location.href = t.id
      } else if (t.redirectType === 39) {
        this.$router.push({ path: '/acthds', query: { id: t.typeId }})
      }
    },
    // 中间 img 跳转
    btnActivityImg: function(t) {
      this.$router.push({ path: '/digitalActivities', query: { id: t.id }})
    },
    // 左右滑动 跳转
    btnList: function(t) {
      const obj = {
        product_type: 1, // 暂时写1
        goodId: t.redirectUrl, // 暂时后段没 goodId
        skuId: t.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    // 为你推荐 跳转
    btnGo: function(t) {
      const obj = {
        product_type: 1, // 暂时写1
        goodId: t.goodId,
        skuId: t.skuId
      }
      this.$store.dispatch('godetail', obj)
    },
    // 金刚位 跳转

    //  brand  值 为前段自己写死判断 自己约定的
    //  brand  11（ 数码家电 日用家具 母婴玩具）
    btnCategory: function(item) {
      this.$router.push({
        path: '/sidebar',
        query: {
          goodTypeId: item.categoryThreeId,
          keyword: item.categoryThreeName,
          brand: 11
        }
      })
    },

    mescrollInit(data) {
      this.mescroll = data
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数,默认10
    upCallback(page, mescroll) {
      // 接口请求数据
      this.getListDataFromNet(
        this.pdType,
        page.num,
        page.size,
        (arr) => {
          // 如果是第一页需手动制空列表
          if (page.num === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr)
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length)
          })
        },
        () => {
          // 联网异常,隐藏上拉和下拉的加载进度
          mescroll.endErr()
        }
      )
    },
    getListDataFromNet: function(
      pdType,
      pageNum,
      pageSize,
      successCallback,
      errorCallback
    ) {
      const newParams = {
        categoryId: this.categoryId,
        page: pageNum,
        pageSize: pageSize
      }
      recommendList(newParams).then((res) => {
        if (Number(res.code) === 200) {
          successCallback(res.data)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.digital {
  .scrollList {
    background: #f6f6f6;
  }

  background: #f6f6f6;

  .digital_img {
    padding: 12px;
    box-sizing: border-box;
    background: #fff;
    margin-top: -12px;
  }

  .digital_list {
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #fff;

    .digital_list_son {
      width: 20%;
      text-align: center;
      margin-bottom: 16px;

      .digital_list_son_img {
        padding: 10px;
        box-sizing: border-box;
      }

      h4 {
        font-size: 14px;
      }
    }
  }

  .digital_mid_list {
    display: flex;
    flex-wrap: wrap;
    margin: 12px;

    .digital_mid_list_son {
      width: 50%;
      padding: 1px;
      box-sizing: border-box;
    }
  }

  .digital_activity_img {
    margin: 12px;
  }

  .digital_activity_list {
    margin-top: 12px;
    background: #fff;

    .group_commend_title {
      padding-top: 12px;
      box-sizing: border-box;
    }

    .group_commend_left {
      overflow: hidden;
      height: 190px;
      margin-top: 12px;
    }

    .box_all {
      background: #fff;
      padding: 10px 0;
      box-sizing: border-box;
      white-space: nowrap;
      overflow-y: auto;
    }

    .box_list {
      width: 120px;
      margin-left: 12px;
      background: #fff;
      display: inline-block;
    }

    .box_list_img {
      width: 120px;
      height: 120px;

      img {
        width: 100%;
      }
    }

    .box_list_span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 18px;
      margin-top: 8px;
    }

    .box_list_type {
      text-align: left;
      margin-top: 12px;

      .box_list_vip_price {
        color: red;
        font-size: 14px;
        margin-top: 2px;
      }

      .box_list_price {
        color: #999;
        text-decoration: line-through;
        font-size: 10px;
      }
    }
  }

  .digital_list_all {
    h4 {
      padding: 14px 0 0px;
    }

    .jd_list_box {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 6px;
      box-sizing: border-box;
      overflow: hidden;

      .jd_list {
        width: 169px;
        height: 260px;
        background: #fff;
        margin-right: 12px;
        float: left;
        margin-bottom: 6px;
        margin-top: 6px;
        border-radius: 6px;
        overflow: hidden;
      }

      .jd_list:nth-child(2n) {
        margin-right: 0px;
      }

      .jd_list_ovs {
        position: relative;

        .jd_list_img {
          width: 100%;
        }
      }

      .jd_list_son {
        padding: 0 7px;
        box-sizing: border-box;
        text-align: left;
        margin-top: 6px;

        .jd_name {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 18px;
        }

        .jd_logo_ov {
          margin-top: 10px;

          .jd_price {
            color: #ff001c;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
