var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"digital"},[_c('mescroll',{attrs:{"up":_vm.mescrollUp,"down":_vm.mescrollDown}},[_c('div',{staticClass:"scrollList",attrs:{"slot":"scrollList"},slot:"scrollList"},[_c('headSearch',{attrs:{"search-out":_vm.headGo}}),_c('div',{staticClass:"digital_img",on:{"click":function($event){return _vm.btnBanner(_vm.listData.banner[0])}}},[_c('img',{attrs:{"src":_vm.listData.banner[0].url,"alt":""}})]),_c('div',{staticClass:"digital_list"},_vm._l((_vm.listData.category),function(item,index){return _c('div',{key:index,staticClass:"digital_list_son",on:{"click":function($event){return _vm.btnCategory(item)}}},[_c('div',{staticClass:"digital_list_son_img"},[_c('img',{attrs:{"src":item.productImage,"alt":""}})]),_c('h4',[_vm._v(_vm._s(item.categoryThreeName))])])}),0),(_vm.temptype == 3 || _vm.temptype == 2 || _vm.temptype == 1)?_c('div',[(
            _vm.temptype == 1 &&
              _vm.listData.activity &&
              _vm.listData.activity.length != 0
          )?_c('div',{staticClass:"digital_mid_list"},_vm._l((_vm.listData.activity[0].goodsInfo),function(item,index){return _c('div',{key:index,staticClass:"digital_mid_list_son",on:{"click":function($event){return _vm.btnActivity(item)}}},[_c('img',{attrs:{"src":item.image,"alt":""}})])}),0):_vm._e(),(
            _vm.temptype == 1 &&
              _vm.listData.activity &&
              _vm.listData.activity.length != 0
          )?_c('div',{staticClass:"digital_activity_img",on:{"click":function($event){return _vm.btnActivityImg(_vm.listData.activity[1].goodsInfo[0])}}},[_c('img',{attrs:{"src":_vm.listData.activity[1].goodsInfo[0].image,"alt":""}})]):_vm._e(),(
            _vm.temptype == 2 &&
              _vm.listData.activity &&
              _vm.listData.activity.length != 0
          )?_c('div',{staticClass:"digital_activity_img",on:{"click":function($event){return _vm.btnActivityImg(_vm.listData.activity[0].goodsInfo[0])}}},[_c('img',{attrs:{"src":_vm.listData.activity[0].goodsInfo[0].image,"alt":""}})]):_vm._e(),(_vm.temptype == 1 && _vm.listData.activity.length > 0)?_c('div',{staticClass:"digital_activity_list"},[_c('div',{staticClass:"group_commend_left"},[_c('div',{staticClass:"box_all"},_vm._l((_vm.listData.activity[
                  _vm.temptype == 1 ? 2 : 1
                ].goodsInfo),function(item,index){return _c('div',{key:index,staticClass:"box_list",on:{"click":function($event){return _vm.btnList(item)}}},[_c('div',{staticClass:"box_list_img"},[_c('img',{attrs:{"src":item.image,"alt":""}})]),_c('div',{staticClass:"box_list_span"},[_vm._v(_vm._s(item.goodsName))]),_c('div',{staticClass:"box_list_type"},[_c('span',{staticClass:"box_list_vip_price"},[_vm._v("¥"+_vm._s(item.vipPrice))]),_c('span',{staticClass:"box_list_price"},[_vm._v("¥"+_vm._s(item.goodsPrice))])])])}),0)])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"digital_list_all"},[_c('h4',[_vm._v("· 为你推荐 ·")]),_c('div',{staticClass:"jd_list_box"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"jd_list",on:{"click":function($event){return _vm.btnGo(item)}}},[_c('div',{staticClass:"jd_list_ovs"},[_c('imgLoading',{staticClass:"jd_list_img",attrs:{"src":item.goodCover}})],1),_c('div',{staticClass:"jd_list_son"},[_c('div',{staticClass:"jd_name"},[_c('span',[_vm._v(_vm._s(item.goodName))])]),_c('div',{staticClass:"jd_logo_ov"},[_c('span',{staticClass:"jd_price"},[_vm._v("¥"+_vm._s(item.goodVipPrice))])])])])}),0)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }